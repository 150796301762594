const LOGIN = 'login'
const DATABASE = 'database'
const TYPES = 'types'
const USERS = 'users'
const TOOLS = 'tools'

export default {
  LOGIN,
  DATABASE,
  TYPES,
  USERS,
  TOOLS
}
