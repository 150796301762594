const mimeTypeMap = {
    '.7z': 'application/x-7z-compressed',
    '.aac': 'audio/aac',
    '.ace': 'application/x-ace-compressed',
    '.aiff': 'audio/aiff',
    '.arj': 'application/x-arj',
    '.avi': 'video/x-msvideo',
    '.bmp': 'image/bmp',
    '.bz': 'application/x-bzip',
    '.bz2': 'application/x-bzip2',
    '.bat': 'application/x-msdownload',
    '.c': 'text/x-c',
    '.cab': 'application/vnd.ms-cab-compressed',
    '.cpp': 'text/x-c',
    '.cs': 'text/x-csharp',
    '.css': 'text/css',
    '.csv': 'text/csv',
    '.dmg': 'application/x-apple-diskimage',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.dot': 'application/msword',
    '.epub': 'application/epub+zip',
    '.flac': 'audio/flac',
    '.flv': 'video/x-flv',
    '.gif': 'image/gif',
    '.go': 'text/plain',
    '.gz': 'application/gzip',
    '.h': 'text/x-c',
    '.heic': 'image/heic',
    '.heif': 'image/heif',
    '.htm': 'text/html',
    '.html': 'text/html',
    '.indd': 'application/octet-stream',
    '.iso': 'application/x-iso9660-image',
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.js': 'application/javascript',
    '.json': 'application/json',
    '.jsx': 'text/jsx',
    '.key': 'application/vnd.apple.keynote',
    '.kt': 'text/plain',
    '.lz': 'application/octet-stream',
    '.lzma': 'application/x-lzma',
    '.m4a': 'audio/mp4',
    '.m4v': 'video/x-m4v',
    '.md': 'text/markdown',
    '.mkv': 'video/x-matroska',
    '.mobi': 'application/x-mobipocket-ebook',
    '.mov': 'video/quicktime',
    '.mp3': 'audio/mpeg',
    '.mp4': 'video/mp4',
    '.mpe': 'video/mpeg',
    '.mpeg': 'video/mpeg',
    '.mpg': 'video/mpeg',
    '.oga': 'audio/ogg',
    '.ogg': 'audio/ogg',
    '.ogv': 'video/ogg',
    '.pdf': 'application/pdf',
    '.odc': 'application/vnd.oasis.opendocument.chart',
    '.odg': 'application/vnd.oasis.opendocument.graphics',
    '.odf': 'application/vnd.oasis.opendocument.formula',
    '.odft': 'application/vnd.oasis.opendocument.formula-template',
    '.odi': 'application/vnd.oasis.opendocument.image',
    '.odm': 'application/vnd.oasis.opendocument.text-master',
    '.odt': 'application/vnd.oasis.opendocument.text',
    '.odp': 'application/vnd.oasis.opendocument.presentation',
    '.ods': 'application/vnd.oasis.opendocument.spreadsheet',
    '.otc': 'application/vnd.oasis.opendocument.chart-template',
    '.otg': 'application/vnd.oasis.opendocument.graphics-template',
    '.oth': 'application/vnd.oasis.opendocument.text-web',
    '.oti': 'application/vnd.oasis.opendocument.image-template',
    '.otp': 'application/vnd.oasis.opendocument.presentation-template',
    '.ots': 'application/vnd.oasis.opendocument.spreadsheet-template',
    '.ott': 'application/vnd.oasis.opendocument.text-template',
    '.opus': 'audio/opus',
    '.pages': 'application/vnd.apple.pages',
    '.php': 'application/x-httpd-php',
    '.pl': 'application/x-perl',
    '.png': 'image/png',
    '.pot': 'application/vnd.ms-powerpoint',
    '.pps': 'application/vnd.ms-powerpoint',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.psd': 'image/vnd.adobe.photoshop',
    '.rar': 'application/x-rar-compressed',
    '.raw': 'image/x-panasonic-raw',
    '.rb': 'text/x-ruby',
    '.rs': 'application/rls-services+xml',
    '.rtf': 'application/rtf',
    '.scala': 'text/x-scala',
    '.sh': 'application/x-sh',
    '.svg': 'image/svg+xml',
    '.sql': 'application/x-sql',
    '.swift': 'text/x-swift',
    '.tar': 'application/x-tar',
    '.tex': 'application/x-tex',
    '.tgz': 'application/gzip',
    '.tif': 'image/tiff',
    '.tiff': 'image/tiff',
    '.ts': 'text/typescript',
    '.tsv': 'text/tab-separated-values',
    '.tsx': 'text/tsx',
    '.txt': 'text/plain',
    '.vob': 'video/x-ms-vob',
    '.wav': 'audio/wav',
    '.weba': 'audio/webm',
    '.webm': 'video/webm',
    '.webp': 'image/webp',
    '.wma': 'audio/x-ms-wma',
    '.wmv': 'video/x-ms-wmv',
    '.xls': 'application/vnd.ms-excel',
    '.xlt': 'application/vnd.ms-excel',
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xml': 'application/xml',
    '.xz': 'application/x-xz',
    '.yaml': 'application/yaml',
    '.yml': 'application/yaml',
    '.z': 'application/x-compress',
    '.zip': 'application/zip',
    '.zipx': 'application/zip'
  }

  export default mimeTypeMap;