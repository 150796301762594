const configuration =
  [
    // Coarse file formats
    {
      icon: 'file-audio',
      extensions: ['wav', 'mp3', 'aac', 'ogg', 'oga', 'flac', 'm4a', 'wma',
        'opus', 'flac', 'aiff', 'weba']
    },
    {
      icon: 'file-text',
      extensions: ['txt', 'rtf', 'html', 'htm', 'epub', 'md', 'tex', 'pages',
        'numbers', 'key', 'mobi', 'indd', 'csv', 'tsv',
        'odt', 'ods', 'odp', 'otp', 'odm', 'ott', 'ots', 'odf', 'odft']
    },
    {
      icon: 'file-video',
      extensions: ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm',
        'mpeg', 'mpg', 'mpe', 'vob', 'm4v', 'ogv']
    },
    {
      icon: 'file-image',
      extensions: ['tif', 'tiff', 'gif', 'jpg', 'jpeg', 'png', 'bmp', 'svg',
        'webp', 'psd', 'raw', 'heif', 'heic', 'odc', 'otc', 'odg', 'otg',
        'odi', 'oti']
    },
    {
      icon: 'file-archive',
      extensions: ['zip', 'rar', '7z', 'tar', 'gz', 'bz', 'bz2', 'xz', 'iso',
        'zipx', 'cab', 'arj', 'lz', 'lzma', 'z', 'tgz', 'ace', 'dmg']
    },
    {
      icon: 'file-code',
      extensions: ['xml', 'js', 'html', 'css', 'c', 'cpp', 'h', 'cs', 'php',
        'rb', 'swift', 'go', 'rs', 'ts', 'json', 'sh', 'bat', 'sql', 'yaml',
        'yml', 'jsx', 'tsx', 'pl', 'scala', 'kt']
    },
    // Fine-grained file formats
    {
      icon: 'file-pdf',
      extensions: ['pdf']
    },
    {
      icon: 'file-word',
      extensions: ['doc', 'dot', 'docx']
    },
    {
      icon: 'file-excel',
      extensions: ['xls', 'xlsx']
    },
    {
      icon: 'file-powerpoint',
      extensions: ['ppt', 'pptx']
    }
  ]


  export default configuration;