
/*
 *  Copyright ETH 2024 Zürich, Scientific IT Services
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *       http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 *
 */
import React from 'react'
import autoBind from 'auto-bind'
import { debounce } from '@mui/material'
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/GetApp'
import messages from '@src/js/common/messages.js'


const color = 'inherit'

class Download extends React.Component {
    constructor(props, context) {
        super(props, context)
        autoBind(this)


        this.controller = this.props.controller
        this.onResize = debounce(this.onResize, 1)
    }


    render() {
        const {
            disabled,
            classes,
            buttonSize,
            onDownload,
            multiselectedFiles,
            className
        } = this.props       

        return (<Button
            key='download-button'            
            classes={classes}
            className={className}
            color={color}
            size={buttonSize}
            variant={className ? '' : 'outlined'}
            disabled={multiselectedFiles.size === 0}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
            >
            {messages.get(messages.DOWNLOAD)}
            </Button>)
    }
}

export default Download